<template>
  <div>
    <Header />
    <div class="body">
      <Banner />
      <OrderSteps />
      <Categories />
      <Faq />
      <Playstore />
      <Newsletter />
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "@/components/Home/NavBar.vue";
import Banner from "@/components/Home/Banner.vue";
import OrderSteps from "@/components/Home/OrderSteps.vue";
import Categories from "@/components/Home/CategoryFolder/Categories.vue";
import Faq from "@/components/Home/Faq.vue";
import Playstore from "@/components/Home/PlaystoreSession.vue";
import Newsletter from "@/components/Home/NewsLetter.vue";
import Footer from "@/components/Home/Footer.vue";

export default {
  components: {
    Header,
    Banner,
    OrderSteps,
    Categories,
    Faq,
    Playstore,
    Newsletter,
    Footer,
  },

  data() {
    return {
      user: false,
      username: "",
    };
  },
};
</script>

<style scoped>
.body-container {
  padding: 0px 7%;
  overflow: hidden;
}

.body {
  overflow-y: scroll;
  height: calc(100vh - 60px);
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: hidden;
}
</style>

