<template>
  <div class="store-container bg-light">
    <div class="row container py-5">
      <div id="playstore_phone" class="col-md-6">
        <img :src="MyPhone" alt="" />
      </div>
      <div id="store" class="col-md-6 mt-0 mt-md-5">
        <h3 class="fs-2 px-4">Download the FoodPlace App</h3>
        <p class="px-4">
          You can access the FoodPlace app on either google playstore or Apple
          store.
        </p>
        <div class="px-3 m-auto store_logo">
          <div><img :src="MyApp" /></div>
          <div><img :src="MyGoogle" /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Playstore",
  data() {
    return {
      MyPhone: require("../../assets/phone1.png"),
      MyGoogle: require("../../assets/google_play.png"),
      MyApp: require("../../assets/app_store.png"),
    };
  },
};
</script>

<style scoped>
.row {
  --bs-gutter-x: 0rem;
}

#store {
  padding-top: 4%;
}

#store p {
  padding-bottom: 5%;
}
.store-container {
  margin: 4% 0%;
}

#playstore_phone {
  text-align: center !important;
}

.store_logo {
  display: flex;
}

.store_logo img {
  width: 80%;
  height: auto;
}

#store h3 {
  font-weight: 600;
  font-size: 50px;
  line-height: 55px;
  padding-right: 5%;
}

#store p {
  font-size: 18px;
  line-height: 25px;
  color: #43424c;
  padding-right: 15%;
}
</style>
