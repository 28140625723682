<template>
  <div class="pb-5">
    <loading
      v-model="isLoading"
      :can-cancel="true"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    />
    
    <div id="product-container" class="row">
      <div class="col-md-3 mb-3" v-for="(menu, index) of allMenus" :key="index">
        <div
          @click="vendorPage(menu)"
          id="card-container"
          class="card box"
        >
          <img
            :src="menu.image"
            class="card-img-top"
            alt="menu image"
          />
          <div class="card-body col-12">
            <div class="product">
              <h5>{{ menu.name }}</h5>
              <p>
                {{ menu.description }}
              </p>
              <div class="price">
                <div class="row col-12">
                  <div class="product-price col-6">&#8358;{{ menu.price }}</div>
                  <div title="Add to Cart" class="addToCartIcon col-6">
                    <i class="fas fa-heart"></i>
                  </div>
                </div>
              </div>
              <div title="View Vendor Page" class="provider">
                {{ menu.vendor_list.vendor.business_name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loading from "vue-loading-overlay";

import router from "@/router";

export default {
  name: "ProductsDisplay",

  props: [ "selectedCategory" ],

  data() {
    return {
      isLoading: false,
      fullPage: true,
    };
  },

  components: {
    Loading,
  },

  methods: {
    ...mapActions({
      add: "general/addToProductCart",
      menus: "general/availableMenu",
      singleVendorDetails: "vendor/getVendorFullDetails",
    }),

    ...mapGetters({
      foodMenus: "general/availableMenus",
    }),

    vendorPage(menu) {
      this.isLoading = true;
      localStorage.setItem("vendor_list_id", menu.vendor_list_id);
      localStorage.setItem("id", menu.vendor_list.vendor_id);
      this.singleVendorDetails().then((data) => {
        if (data == undefined) {
          router.push({ name: "singleVendor" });
          this.isLoading = false;
        }
      });
    },
  },

  async created() {
    this.menus();
  },

  computed: {
    allMenus() {
      return this.foodMenus();
    },

    filterCategories() {
      return true
    }
  },
};
</script>

<style scoped>
#product-container {
  margin-top: 3%;
}
.card {
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  cursor: pointer;
}
.card-body {
  padding: 1rem 1rem 0rem 1rem;
}

.product h5 {
  color: rgba(4, 3, 15, 1);
  font-weight: 600;
  font-size: 15px;
}

.addToCartIcon {
  cursor: pointer;
  text-align: right;
}

.addToCartIcon i:hover {
  cursor: pointer;
  color: red;
}

.product-price {
  color: rgba(133, 77, 39, 1);
}

.product p {
  color: rgba(57, 105, 103, 1);
  font-size: 13px;
  margin-block-start: 0em !important;
  margin: 0px !important;
  padding-left: 0px !important;
  /* padding-right: 5%; */
}

.price {
  display: flex;
  justify-content: space-between;
}

.price i {
  color: rgba(185, 186, 184, 1);
}

.provider {
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

#card-container img {
  padding: 5px;
  border-radius: 12px;
  min-height: 200px;
  height: 200px;
}
.row.display-flex {
    display: flex;
    flex-wrap: wrap;
}

.row.display-flex>[class*='col-'] {
    flex-grow: 1;
}

.box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    flex-direction: column;
}
</style>
