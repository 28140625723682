<template>
  <div class="order">
    <div class="fs-3 mb-5">
      <h3 class="font-bold fs-3 mt-4 mt-md-0">How do I get my order</h3>
    </div>
    <div class="row">
      <div id="steps" class="col-md-4">
        <div class="order-background-1">
          <img :src="MyDelivery" />
        </div>
        <h5 class="fs-4 font-bold">Set Delivery location</h5>
        <p>Enter location where you want us to deliver</p>
      </div>
      <div id="steps" class="col-md-4">
        <div class="order-background">
          <img :src="MyChoice" />
        </div>
        <h5 class="fs-4 font-bold">Choose the product</h5>
        <p>Browse shop that deliver near you</p>
      </div>
      <div id="steps" class="col-md-4">
        <div class="order-background">
          <img :src="MyDelivery" />
        </div>
        <h5 class="fs-4 font-bold">Get it delivered to you</h5>
        <p>Your order will be delivered to you in no time</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderSteps",
  data() {
    return {
      MyChoice: require("../../assets/Choice.png"),
      MyDelivery: require("../../assets/Delivery.png"),
      MyAddress: require("../../assets/Address.png"),
    };
  },
};
</script>

<style scoped>
.order h3 {
  color: rgba(4, 3, 15, 1);
  text-align: center;
  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
  justify-content: center !important;
}

.row {
  --bs-gutter-x: 0rem;
}
.order {
  padding: 0px 15%;
  padding-top: 5%;
  text-align: center;
}

.order h4 {
  color: rgba(4, 3, 15, 1);
}

.order p {
  color: rgba(67, 66, 76, 1);
  font-size: 14px;
}

#steps:hover {
  background: #ffffff;
  box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.order-background img {
  background: rgba(255, 245, 238, 1);
  padding: 10px;
  margin: 20px;
}

.order-background-1 img {
  background: rgba(248, 248, 255, 1);
  padding: 10px;
  margin: 20px;
}
</style>
