<template>
  <div class="banner-background pb-5">
    <div class="row mb-5">
      <div id="banner-text" class="col-md-6 col-12">
        <h4>Food for strength, Nice Tasty Food for your Enjoyment</h4>
        <p>
          Get freshly cooked, Nigerian, African and Inter-continental dishes at
          your doorstep. Order lunch, fuel for meetings or late-night deliveries
          to the office.
        </p>
        <div class="mt-5 mb-3">
        <button
          v-show="LoggedIn"
          @click="getStarted()"
          id="btn"
          class="btn btn"
          type="submit"
        >
          Get Started<span class="icon"
            ><i class="fas fa-arrow-right"></i
          ></span>
        </button>
        </div>
        <div class="location">
          <div class="address mb-2">
            <i class="fas fa-map-marker-alt"></i> Delivering to
          </div>
          <!-- <div class="input-group mb-3">
            <input
              type="text"
              class="form-control"
              placeholder="Enter your location"
              id="location"
            />
          </div> -->
          <vue-google-autocomplete
            id="map"
            class="locationInput"
            classname="form-control form-control-lg border-0"
            placeholder="Enter your location"
            v-on:placechanged="getAddressData"
          >
          </vue-google-autocomplete>
        </div>
      </div>
      <div id="my-img" class="col-md-6 d-none p-5 text-center m-auto d-md-block">
        <img class="img-fluid" :src="MyBanner" />
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import VueGoogleAutocomplete from "vue-google-autocomplete";
export default {
  components: { VueGoogleAutocomplete },
  name: "Banner",
  data() {
    return {
      MyBanner: require("../../assets/banner.png"),
      LoggedIn: true,
      address: "",
    };
  },

  mounted() {
    this.$refs.address;
  },

  // mounted(){
  //   new VueGoogleMaps.gmapApi.places.Autocomplete(
  //     document.getElementById("location")
  //   );
  // },

  methods: {
    getStarted() {
      router.push({ name: "signUp" });
    },

    getAddressData: function (addressData) {
      this.address = addressData;
    },
  },

  async created() {
    if (this.$store.state["auth"].token !== null) {
      this.LoggedIn = false;
    } else {
      this.LoggedIn = true;
    }
  },
};
</script>

<style scoped>
.row {
  --bs-gutter-x: 0rem;
}

.locationInput {
  outline: none !important;
  box-shadow: none;
  border-color: rgb(196, 192, 192);
}

#my-img img {
  width: 80%;
  height: auto;
}
.banner-background {
  background-color: #f8e9f2;
  padding: 0% 7%;
  padding-top: 5%;
  padding-bottom: 3%;
}

#banner-text h4 {
  font-family: Nunito Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
  color: #04030f;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 15px;
}

#banner-text p {
  font-family: Nunito Sans;
  font-size: 15px;
  padding-right: 25%;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #43424c;
}

#btn {
  color: #854d27;
  padding: 10px 35px;
  background-color: #854d27;
  color: #fff;
  margin-right: 35%;
}

.border-primary {
  border-color: #854d27 !important;
}

.icon {
  margin-left: 20px;
}

.location {
  padding-top: 7%;
}

.location i {
  color: #854d27;
}

.address {
  color: #04030f;
}
</style>
