<template>
  <div class="filter-container">
    <h1>Categories</h1>
    <div class="py-2 mb-3">
      <span v-for="cat in activeCategories" :key="cat" class="">
        <button :id="selectedCat == cat.name ? 'btn-active' : 'btn'" @click="selectedCat = cat.name" class="btn btn mb-2">{{ cat.name }}</button>
      </span>
    </div>

    <div>
      <FilterCategory />
    </div>

    <div>
      <ProductsDisplay :selectedCategory="selectedCat"/>
    </div>
  </div>
</template>

<script>
import FilterCategory from "../CategoryFolder/FilterCategory.vue";
import ProductsDisplay from "../CategoryFolder/ProductsDisplay.vue";
import { mapActions } from "vuex";

export default {
  name: "Categories",

  data() {
    return {
      Categories: [],
      activeCategories: [],
      selectedCat: 'All Categories'
    };
  },
  components: {
    FilterCategory,
    ProductsDisplay,
  },

  methods: {
    ...mapActions({
      category: "general/getCategory",
    }),
  },

  async created() {
    this.category();
    this.Categories = this.$store.state["general"].categories;
    this.activeCategories = JSON.parse(JSON.stringify(this.Categories));

    let all = {
        "id": "",
        "name": 'All Categories',
    }
    this.activeCategories.unshift(all)
  },
};
</script>

<style scoped>
.filter-container {
  margin-top: 4%;
  margin-bottom: 3%;
}
.filter-container h1 {
  color: rgba(4, 3, 15, 1);
  font-size: 18px;
  font-weight: 600;
}

.filter-btns {
  display: flex;
  justify-content: space-between;
  padding-bottom: 3%;
}
#btn {
  padding: 10px 15px;
  border-radius: 5px;
  outline: none;
  margin-right: 10px;
  text-align: center;
  /*border: 0.5px solid #e0e0e0;*/
}
#btn:hover {
  background-color:#854D27;
  color: #fff;
}

#btn-active {
  background-color:#854D27;
  color: #fff;
  padding: 10px 15px;
  border-radius: 5px;
  outline: none;
  margin-right: 10px;
  text-align: center;
}

#btn:hover {
  background-color: #854d27;
  color: #fff;
}

.filter-container {
  padding: 0px 7%;
}

@media only screen and (max-width: 600px) {
  .filter-btns {
    display: block;
  }
}
</style>
