<template>
  <div class="mt-5">
    <div class="filter-container">
      <div>
        <h3>Nigerian</h3>
      </div>
      <div class="filter">
        <div class="search">
          <div class="right-inner-addon input-container">
            <i class="fa fa-search"></i>
            <input type="text" class="form-control form-control-lg" placeholder="Search here" />
          </div>
        </div>
        <div>
          <div class="right-inner-addon input-container">
            <i class="fas fa-filter"></i>
            <input type="text" class="form-control form-control-lg" placeholder="Filter" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterCategory",
};
</script>

<style scoped>
.filter-container {
  display: flex;
  justify-content: space-between;
}

.filter-container h3 {
  color: rgba(4, 3, 15, 1);
  font-size: 18px;
  font-weight: 600;
}

.search {
  margin-right: 3%;
}
.filter {
  display: flex;
}

.right-inner-addon {
  position: relative;
}
.right-inner-addon input {
  padding-right: 35px !important;
}
.right-inner-addon i {
  position: absolute;
  right: 0px;
  color: rgba(185, 186, 184, 1);
  padding: 12px 12px;
  pointer-events: none;
}

input {
  width: 100%;
  margin: 0em !important;
  box-sizing: border-box;
}

@media only screen and (max-width: 600px) {
  .filter-container {
    display: block;
  }
}
</style>
