<template>
  <div class="newsletter-container">
    <div class="img-holder">
      <div class="align-center">
        <p class="fs-4">Subscribe to our daily newsletter</p>
        <form class="site-search single-block-form">
          <input type="text" name="q" id="search-site" class="sbf__input" />
          <label for="search-site" class="-vis-hidden">Subscribe</label>
          <input type="submit" value="Subscribe" class="sbf__button button" />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Newsletter",
  data() {
    return {
      Background: require("../../assets/newsletter.png"),
    };
  },
};
</script>

<style scoped>
.newsletter-container {
  padding: 0% 7%;
}
.img-holder {
  background-image: url("../../assets/newsletter.png"),
    linear-gradient(rgba(60, 35, 46, 0.6), rgba(108, 69, 87, 0.6));
  height: 18em;
  border-radius: 25px;
  background-size: cover;
  text-align: center;
}

.align-center {
  width: 800px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.align-center p {
  color: #fff;
  font-weight: 600;
  font-size: 25px;
  line-height: 34px;
  /* text-align: left !important; */
}

.single-block-form {
  display: inline-block;
  position: relative;
  width: 400px; /*Can be whatever, can be min/max */
}
.sbf__input {
  width: 100%;
}
.sbf__button {
  border-radius: 0 4px 4px 0;
  position: absolute;
  right: 0;
  top: 0;
}

/* pretty */
.-vis-hidden {
  border: 0 none;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

input[type="text"] {
  border-radius: 4px;
  color: inherit;
  display: inline-block;
  line-height: normal;
  margin-bottom: 0.75em;
  background-color: rgba(255, 255, 255, 1);
  padding: 12px 10px 10px;
  vertical-align: middle;
  border: none;
  outline: none;
  border: 1px solid #ccc;
}

.button {
  background-color: rgba(133, 77, 39, 1);
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: normal;
  line-height: normal;
  margin-bottom: 0;
  padding: 12px 20px 12px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms ease 0s;
  vertical-align: middle;
  white-space: nowrap;
  /* margin-top: 20px; */
}

.row {
  --bs-gutter-x: 0rem;
}

@media only screen and (max-width: 700px) {
  .single-block-form {
    display: inline-block;
    position: relative;
    /* width: 350px;  */
  }
}

@media only screen and (max-width: 600px) {
  .single-block-form {
    display: inline-block;
    position: relative;
    overflow: hidden;
    width: 300px;
  }

  .align-center p {
    font-size: 13px;
  }
}

@media only screen and (max-width: 500px) {
  .align-center p {
    font-size: 13px;
  }

  /* .button {
    display: block;
  } */
}
</style>
