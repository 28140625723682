<template>
  <div class="faq mt-5">
    <div>
      <h3 class="fs-2 mt-5 ">Frequently Asked Questions</h3>
    </div>
    <div>
      <section class="mt-5 px-3 px-md-0">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="accordion accordion-flush row" id="faqlist">
                <div class="p-3 col-md-6">
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#faq-content-1">
                        I got the wrong food, what should I do?
                      </button>
                    </h2>
                    <div id="faq-content-1" class="accordion-collapse collapse" data-bs-parent="#faqlist">
                      <div class="accordion-body">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the industry's
                        standard dummy text ever since the 1500s.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="p-3 col-md-6">
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#faq-content-2">
                        The Delivery man is unreaceable, what should I do?
                      </button>
                    </h2>
                    <div id="faq-content-2" class="accordion-collapse collapse" data-bs-parent="#faqlist">
                      <div class="accordion-body">
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when looking
                        at its layout. The point of using Lorem Ipsum is that it
                        has a more-or-less normal distribution of letters, as
                        opposed to using 'Content here.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="p-3 col-md-6">
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#faq-content-3">
                        I forgot
                      </button>
                    </h2>
                    <div id="faq-content-3" class="accordion-collapse collapse" data-bs-parent="#faqlist">
                      <div class="accordion-body">
                        Contrary to popular belief, Lorem Ipsum is not simply
                        random text. It has roots in a piece of classical Latin
                        literature from 45 BC, making it over 2000 years old.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="p-3 col-md-6">
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#faq-content-4">
                        I got the wrong food, what should I do?
                      </button>
                    </h2>
                    <div id="faq-content-4" class="accordion-collapse collapse" data-bs-parent="#faqlist">
                      <div class="accordion-body">
                        Contrary to popular belief, Lorem Ipsum is not simply
                        random text. It has roots in a piece of classical Latin
                        literature from 45 BC, making it over 2000 years old.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="p-3 col-md-6">
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#faq-content-5">
                        Where does it come from?
                      </button>
                    </h2>
                    <div id="faq-content-5" class="accordion-collapse collapse" data-bs-parent="#faqlist">
                      <div class="accordion-body">
                        Contrary to popular belief, Lorem Ipsum is not simply
                        random text. It has roots in a piece of classical Latin
                        literature from 45 BC, making it over 2000 years old.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "Faq",
};
</script>

<style scoped>
.faq h3 {
  color: rgba(4, 3, 15, 1);
  text-align: center;
  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
  justify-content: center !important;
}

.accordion-item {
  background: #ffffff;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 3px
}

.accordion-collapse {
  border: 0;
}

.accordion-button {
  padding: 0px;
  font-weight: bold;
  margin: 15px 10px;
  padding-top: 8px;
  padding-right: 8px;
  border: 0;
  font-size: 18px;
  color: #333333;
  text-align: left;
  /* border-bottom: 1px solid #ddd;  */
}

.accordion-button:focus {
  box-shadow: none;
  border: none;
}

.accordion-button:not(.collapsed) {
  background: none;
}

.accordion-body {
  padding: 20px;
  background: #ffffff;
  /* box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
    border-radius: 8px; */
}

.row {
  --bs-gutter-x: 0rem;
}

.accordion-button::after {
  width: auto;
  height: auto;
  content: "+";
  font-size: 20px;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  font-weight: 100;
  padding: 0px 10px;
  margin-top: 5px;
  margin-right: 10px;
  color: #1b6ce5;
  transform: translateY(-4px);
}

.accordion-button:not(.collapsed)::after {
  width: auto;
  height: auto;
  background-image: none;
  content: "-";
  font-size: 20px;
  transform: translate(-5px, -4px);
  transform: rotate(0deg);
}

@media (min-width: 768px) {
  .offset-md-3 {
    margin-left: 0%;
  }
}
</style>
